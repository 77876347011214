<template>
  <b-container class="grade-modal-form">

    <b-table-simple bordered>
      <b-tr class="single-table">
        <b-td class="header">
          <b>{{ experimentColorItems.title }}</b>
        </b-td>
        <b-td>
          <strong :class="'text-' + getEvaluationColor(experimentColorItems.color)">
            {{ experimentColorItems.color.toUpperCase() }}
          </strong>
        </b-td>
      </b-tr>
    </b-table-simple>

    <b-table :fields="evaluationsFields"
             :items="evaluationsItems"
             bordered
             class="table-responsive"
             responsive
             striped
             thead-class="d-none">
      <template v-slot:cell(title)="row">
        <strong class="header">{{ row.value }}</strong>
      </template>
      <template v-slot:cell(feasibility)="row">
        <strong :class="'text-' + getEvaluationColor(row.value)">
          {{ row.value.toUpperCase() }}
        </strong>
      </template>
    </b-table>

    <b-table-simple bordered>
      <b-tr class="single-table">
        <b-td class="header">
          <b>{{ technicalCommentForUserItems.title }}</b>
        </b-td>
        <b-td>
          {{ technicalCommentForUserItems.comment }}
        </b-td>
      </b-tr>
    </b-table-simple>

  </b-container>
</template>

<script>
  import trafficLightsOptions from '@/json/trafficLightsAcceptanceMarkSafety';

  export default {
    props: {
      evaluationsFields: {
        type: Array,
        required: false,
      },
      value: {
        type: Object,
        default: () => {
        },
      },
    },
    data() {
      return {
        proposal: JSON.parse(JSON.stringify(this.value)),
        trafficLightsOptions,
      };
    },
    computed: {
      evaluationsItems() {
        return [
          {
            title: 'Safety',
            comment: this.proposal.experiment_color?.safety_evaluation?.comments_review ?? '-',
            feasibility: this.proposal.experiment_color?.safety_evaluation?.color ?? '-',
          },
          {
            title: 'Radiation',
            comment: this.proposal.experiment_color?.radiation_evaluation?.comments_review ?? '-',
            feasibility: this.proposal.experiment_color?.radiation_evaluation?.color ?? '-',
          },
          {
            title: 'Technical comment',
            comment: this.proposal.technical_evaluation?.comments ?? '-',
            feasibility: this.proposal.technical_evaluation?.feasibility ?? '-',
          },
        ];
      },
      experimentColorItems() {
        return {
          title: 'Experiment color',
          color: this.proposal.experiment_color?.evaluated_color ?? '-',
        };
      },
      technicalCommentForUserItems() {
        return {
          title: 'Technical comment for user',
          comment: this.proposal.technical_evaluation?.comments_for_user ?? '-',
        };
      },
    },
    methods: {
      getEvaluationColor(evaluation) {
        let variant = 'secondary';
        this.trafficLightsOptions.forEach((option) => {
          if (option.value === evaluation) {
            variant = option.disabledVariant;
          }
        });
        return variant;
      },
    },
  };
</script>

<style lang="scss" scoped>
  .grade-modal-form {
    padding: 0 0 1rem 0 !important;
    margin: 0 0 1rem 0 !important;
    max-width: none;
  }

  .single-table {
    background-color: #e9ecef;
  }
</style>
